import { Injectable } from "@angular/core";
import { DataBaseService } from "./data-base.service";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Subject, Observable } from "rxjs";
import { ProductCategoryDto } from "../model/ProductCategoryDto";

@Injectable()
export class ProductCategoriesDataService extends DataBaseService {
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(httpClient: HttpClient) {
    super(httpClient);

    this.apiEndpoint += "/productcategories";
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getCategories(): Observable<ProductCategoryDto[]> {
    let url = this.apiEndpoint;

    return this.httpClient.get<ProductCategoryDto[]>(url).pipe(map((response) => this.convertDateStringsToDates(response)));
  }

  getCategoryByKey(categoryKey: string): Observable<ProductCategoryDto> {
    let url = this.apiEndpoint + `/bykey/${categoryKey}`;

    return this.httpClient.get<ProductCategoryDto>(url).pipe(map((response) => this.convertDateStringsToDates(response)));
  }

  getCategoryByKeyWithDetails(categoryKey: string): Observable<ProductCategoryDto> {
    let url = this.apiEndpoint + `/bykey/${categoryKey}/details`;

    return this.httpClient.get<ProductCategoryDto>(url).pipe(map((response) => this.convertDateStringsToDates(response)));
  }
}
